import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <UserProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/app/" element={<Login />} />
            <Route path="/app/dashboard" element={<Dashboard />} />
          </Routes>
          <ToastContainer position="bottom-center"/>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
