import React, { useEffect } from 'react';
import { useUser } from '../context/UserContext';
import BarcodeScanner from './BarcodeScanner';

const Dashboard = () => {
  const { user } = useUser();

  useEffect(() => {
    if (user.length === 0) {
      window.location.href = "/app";
    }
  }, [user]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     toast.info('This is a periodic alert!');
  //   }, 15000);

  //   return () => clearInterval(interval);
  // }, []);

  const dummyOrders = [
    { id: 1, item: 'Item 1', quantity: 2, price: '$20' },
    { id: 2, item: 'Item 2', quantity: 1, price: '$10' },
    { id: 3, item: 'Item 3', quantity: 5, price: '$50' },
  ];

  return (
    <div className="page-content mt-3">
      <div className="mx-3">
        <div className='card'>
          <div className='card-body p-0'>
            <BarcodeScanner user={user}/>
          </div>
        </div>
      </div>
      {/* <div className="mx-3">
        <div className='card'>
          <div className='card-body'>
            <h4 className="card-title">Users List</h4>
            <div className='row'>
              <div className='col-md-12'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody className='table-striped'>
                    {dummyOrders.map((order) => (
                      <tr key={order.id}>
                        <td className="border-b">{order.id}</td>
                        <td className="border-b">{order.item}</td>
                        <td className="border-b">{order.quantity}</td>
                        <td className="border-b">{order.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div >
          </div>
        </div>
      </div> */}
    </div>
    // <div className="min-h-screen bg-gray-100">
    //   <div className="p-6 bg-white rounded shadow-md">
    //     <div className="pb-6">
    //       <BarcodeScanner />
    //     </div>
    //     <div className=" ml-4">
    //       <h2 className="text-2xl font-bold mb-4 text-center">Order Data</h2>
    //       <table className="min-w-full bg-white">
    //         <thead>
    //           <tr>
    //             <th className="py-2 px-4 border-b">ID</th>
    //             <th className="py-2 px-4 border-b">Item</th>
    //             <th className="py-2 px-4 border-b">Quantity</th>
    //             <th className="py-2 px-4 border-b">Price</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {dummyOrders.map((order) => (
    //             <tr key={order.id}>
    //               <td className="py-2 px-4 border-b">{order.id}</td>
    //               <td className="py-2 px-4 border-b">{order.item}</td>
    //               <td className="py-2 px-4 border-b">{order.quantity}</td>
    //               <td className="py-2 px-4 border-b">{order.price}</td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Dashboard;
