import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { toast } from 'react-toastify';

const BarcodeScanner = ({user}) => {
  const [data, setData] = useState('No result');

  const handleScan = (result) => {
    if (result) {
      setData(result.text);
      send_request(result.text);
    }
  };
  const send_request = async (data) => {
    const formdata = new FormData();
      formdata.append("type", "update_data");
      formdata.append("users", user[0].id);
      formdata.append("role", user[0].role);
      formdata.append("data", data);
      const requestOptions = { method: "POST", body: formdata, redirect: "follow" };
      
      try {
        const response = await fetch('https://lookhype.com/dashboard/backend/', requestOptions);
        const result = await response.json();
        if (result.status) {
          toast.success(result.message, { autoClose: 3000 });
        } else {
          toast.error(result.message, { autoClose: 3000 });
        }
      } catch (error) {
        toast.error('An error occurred while updating data.', { autoClose: 3000 });
      }
  }
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <QrReader
        className='qr-reader'
        delay={2000}
        onError={handleError}
        onResult={handleScan}
        constraints={{ facingMode: 'environment' }}
      />
      <style>
        {`
          .qr-reader div {
            width: 100%;
          }
          .qr-reader div video {
            height: 100% !important;
          }
        `}
      </style>
    </div>
  );
};

export default BarcodeScanner;
